

















































import { Vue, Component } from 'vue-property-decorator'
import { FormSchema } from 'vue-form-generator'
import { toastMapper } from '@/store/modules/toast'
import UserAPI from '@/api/requests/user'
import { userMapper } from '@/store/modules/user'
import { regMail } from '@/config/default/regex'
import { minLengthPassword } from '@/config/default/user'
import Config from '@/config/default/config'
import { roles } from '@/config/types/roles'

const Mappers = Vue.extend({
  computed: { ...userMapper.mapGetters(['currentUser']) },
  methods: {
    ...toastMapper.mapActions(['pushToast']),
    ...userMapper.mapActions(['fetchCurrentUser'])
  }
})

@Component
export default class AuthUser extends Mappers {
  private isValidForm = false
  private authData = {
    email: '',
    password: ''
  }

  private schema: FormSchema = {
    fields: [
      {
        type: 'bootstrap',
        inputType: 'email',
        model: 'email',
        required: true,
        styleClasses: 'wm-100',
        label: 'Почта',
        placeholder: 'student-leti@etu.ru',
        hint: 'Введите почту, с которой были зарегистрированы в данной системе',
        validator(value, field, model) {
          if (!regMail.test(model.email)) {
            return ['Проверьте правильность ввода почты']
          }
          return []
        }
      },
      {
        type: 'bootstrap',
        inputType: 'password',
        model: 'password',
        required: true,
        styleClasses: 'wm-100',
        label: 'Пароль',
        placeholder: 'Пароль',
        validator(value, field, model) {
          if (model.password.length < minLengthPassword) {
            return [`Пароль содержит меньше ${minLengthPassword} символов`]
          }
          return []
        }
      }
    ]
  }

  private formOptions = {
    validateAfterLoad: false,
    validateAfterChanged: true,
    validateAsync: true
  }

  private redirect = this.$route.query?.redirect ?? ''
  private isProduction = process.env.NODE_ENV === 'production'
  private onValidated(isValid: boolean) {
    this.isValidForm = isValid
  }

  private async logByUser() {
    try {
      await UserAPI.login(this.authData.email, this.authData.password)
      UserAPI.loginRedirect([], this.redirect)
      this.toMainPage()
    } catch {
      await this.pushToast({
        error: true,
        title: 'Ошибка',
        message: 'Неправильный адрес электронной почты или пароль!',
        time: 5
      })
    }
  }
  private async prepareLogin() {
    if (this.authData.email === '') {
      const emailInput = document.querySelector(
        'input[type="email"]'
      ) as HTMLInputElement
      if (emailInput) {
        emailInput.focus()
      }
      return
    }
    if (this.authData.password === '') {
      const passwordInput = document.querySelector(
        'input[type="password"]'
      ) as HTMLInputElement
      if (passwordInput) {
        passwordInput.focus()
      }
      return
    }
    this.logByUser()
  }
  private logDev() {
    UserAPI.loginRedirect(
      [roles.admin, roles.teacher, roles.curator, roles.contentmanager],
      '/auth/dev?'
    )
  }

  private forgotPassword() {
    this.$router.push('/password-recovery')
  }

  private toMainPage() {
    this.fetchCurrentUser().then(() => {
      this.$router.push(Config.routing.homePage.path)
    })
  }
}
